import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Input, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { RefreshSharp } from '@mui/icons-material';

import GET_ALL_INBOUND_JOBS from '@graphql/TH_getAllInboundJobs';

import { dateToString, timestampToDate } from '@utils/helpers/date';
import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TablePaginationStyled, TableStyled } from '@components/Styles/TableStyles';
import { ModalStyled } from '@components/Styles/ModalStyles';
import { SpaceActionButton } from '@components/Styles/ButtonStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';
import { isNaN } from 'lodash';
import WarehouseInInboundJob from '../WarehouseInInboundJob';

function WarehouseInList() {
  const [addInboundJobModal, setAddInboundJobModal] = useState(false);
  const [modalActions, setModalActions] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const location = useLocation();

  const {
    loading, data, refetch, count: total, filters,
  } = useList({ query: GET_ALL_INBOUND_JOBS });
  const {
    pageSize, currentPage, setCurrentPage, setSearchQuery,
  } = filters;

  const filterIcon = (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
  const filterDropdown = ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined style={{ color: '#ffffff' }} />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );

  const columns = useMemo(() => [
    {
      title: 'Job No.',
      dataIndex: 'JobNumber',
      key: 'JobNumber',
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleRowClick(record?.Id)}
        >
          {' '}
          {text}
        </Button>
      ),
    },
    {
      title: 'Job Date',
      dataIndex: 'JobDate',
      key: 'JobDate',
      render: (text) => timestampToDate(text, true),
    },
    {
      title: 'PO Number',
      dataIndex: 'PurchaseOrder',
      key: 'PurchaseOrder',
      filterDropdown,
      filterIcon,
      onFilter: (value, record) => record?.PurchaseOrder?.PONumber?.toLowerCase().includes(value.toLowerCase()),
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.PONumber}
        </span>
      ),
    },
    {
      title: 'Person in Charge',
      dataIndex: 'PersonInCharge',
      key: 'PersonInCharge',
    },
    {
      title: 'Arrival Date',
      dataIndex: 'ArrivalDate',
      key: 'ArrivalDate',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {dateToString(text)}
        </span>
      ),
    },
    {
      title: 'MR Printed Date',
      dataIndex: 'MrPrintedDate',
      key: 'MrPrintedDate',
      render: (text) => timestampToDate(text),
    },
    {
      title: 'Created By',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
    },
  ], []);

  const handleRefresh = useCallback(() => {
    refetch();
  }, []);

  const handleSetModalActions = (actions) => {
    setModalActions(actions);
  };

  const handleAddClick = useCallback(() => {
    setSelectedRecordId(null);
    setAddInboundJobModal(true);
  }, []);

  const handleCancel = useCallback(() => {
    setSelectedRecordId(null);
    setAddInboundJobModal(false);
  }, []);

  const handleRowClick = useCallback((id) => {
    setSelectedRecordId(id);
    setAddInboundJobModal(true);
  }, []);

  const handleSuccess = useCallback((closeModal) => {
    refetch();

    if (closeModal) {
      setAddInboundJobModal(false);
    }
  }, []);

  const handleChangePage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    if (id) {
      const parsedId = parseInt(id, 10);
      if (!isNaN(parsedId)) {
        setSelectedRecordId(parsedId);
        setAddInboundJobModal(true);
      }
    }
  }, []);

  return (
    <ContentStyled>
      <SpaceActionButton>
        <Search
          placeholder="Search..."
          allowClear
          onSearch={handleSearch}
          style={{ width: 304 }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleRefresh}
          icon={<RefreshSharp />}
          tooltipProps={{ title: 'Refresh' }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleAddClick}
          icon={<PlusOutlined />}
          tooltipProps={{ title: 'Add Inbound Job' }}
        />
      </SpaceActionButton>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />
      <TablePaginationStyled current={currentPage} defaultCurrent={1} pageSize={pageSize} total={total} onChange={handleChangePage} showSizeChanger={false} />
      {addInboundJobModal && (
        <ModalStyled
          title={!selectedRecordId ? 'Add New Inbound Job' : 'Inbound Job Details'}
          open={addInboundJobModal}
          onCancel={handleCancel}
          maskClosable={false}
          keyboard={false}
          style={{ top: '54px' }}
          styles={{ body: { height: 'calc(90vh - 108px)', overflow: 'auto' } }}
          width="95%"
          footer={modalActions}
        >
          <WarehouseInInboundJob
            onSetActions={handleSetModalActions}
            recordId={selectedRecordId}
            onSuccess={handleSuccess}
          />
        </ModalStyled>
      )}
    </ContentStyled>
  );
}

export default memo(WarehouseInList);
